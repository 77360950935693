import styled, { css } from 'styled-components';

const Layout = styled.div`
  width: 280px;
  width: 22.5%;
  border-radius: 19px;
  ${({ isOpen }) => isOpen && css`
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}
  > div {
    > div {
      background-color: #dedcdc;
      position: relative;
      min-width: 177px;
    }
  }
  position: relative;
  left: 44%;
  bottom: 32px;
  

  ${({ isCompanyDashboard }) => isCompanyDashboard && css`
    float: left;
    > div {
      > div {
        float: left;
        width: 100%;
      }
    }
  `}
`;

const Button = styled.button`
  width: 100%;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
  padding: 0 10px;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  position: relative;
  text-transform: capitalize;
  display: ${({flex}) => flex && 'flex'};
  @media (max-width: 500px) {
    justify-content: center;
    margin-top: ${({isReports}) => isReports ? '0px' : '-58px'};
  }
  .title {
    margin: 0 5px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Rubik-Regular;
    text-align: right;
  }
  .companyname {
    margin: 0 5px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Rubik-Regular;
    width: 132px;
    text-align: right;
    @media (max-width: 500px){
      width: unset;
    }
  }
  .dropDown {
    display: inline-block;
    text-align: right;
    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      vertical-align: middle;
      border-top: 6px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      float: right;
      margin-top: 7px;
      margin-left: 7px;
    }
  }

  > svg {
    float: left;
    margin-right: 7px;
    margin-top: 2px;
  }

  &:hover, &:active, &:focus {
    outline: none;
  }

  ${({ isCompanyDashboard }) => isCompanyDashboard && css`
    float: left;
  `}
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0px;
  position: absolute;
  width: 100%;
  background-color: #282C37;
  background-color: white;
  z-index: 1000;
  border-radius: 4px;
`;

const MenuItem = styled.li`
  line-height: 24px;
  cursor: pointer;
  position: relative;
  padding: ${({padding}) => padding ? '10px 15px' : '10px 0px'};
  font-size: 16px;
  line-height:17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0D4270;
  color: ${({color}) => color ? 'red' : '#0D4270'};
  border-radius: 4px;
  border-bottom: 3px solid rgba(156, 156, 156, 0.1);
  font-family: Rubik;
  ${({ isActive }) => isActive && css`
    background-color: white;
    color: red;
    color: ${({color}) => color ? '#0D4270' : '#0D4270'};
    font-family: ${({color}) => color ? 'Rubik-Medium' : 'Rubik'};
    > div {
      display: inline-block;
    }
  `}
`;

const MenuItemNew = styled.li`
  display: flex;
  line-height: 24px;
  cursor: pointer;
  position: relative;
  padding: 10px 15px;
  font-size: 14px;
  line-height:17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 11px 0;
  color: #0D4270;
  border-radius: 4px;
  border-bottom: 3px solid rgba(156, 156, 156, 0.1);
  font-family: Rubik;
  ${({ isActive }) => isActive && css`
    background-color: white;
    color: #9c9c9c;
    color: ${({color}) => color ? '#0D4270' : '#0D4270'};
    font-family: ${({color}) => color ? 'Rubik-Medium' : 'Rubik'};
    > div {
      display: inline-block;
    }
  `}
`;

const CheckMark = styled.span`
  display:block;
  margin: 1px 0 0 3px;
  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 6px;
    border-style: solid;
    border-color: #282C37;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }
`;
const CheckMarkContainer = styled.div`
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%; 
  background-color:#fff;
  margin: 0 35px 0 4px;
  >img{
    height: auto;
    width: 25px;
  }
`;

const CheckMarkContainerNew = styled.div`

  width: 10px;
  height: 10px;
  border-radius: 50%; 
  background-color:#fff;
  margin: 0 25px 0 4px;
  >img{
    width: auto;
    height: 25px;
  }
`;

const DropdownHeading = styled.div`
  width:100%;
  display: flex;
  padding: 16px;
  border-bottom: 3px solid rgba(156, 156, 156, 0.1);

  >div:first-child{
    width: 75%;
      text-transform: capitalize;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #0D4270;
     }
     >div:last-child{
       width: 25%;
       display: flex;
       justify-content: flex-end;
       >img{
         width: auto;
         height: 20px;
         cursor: pointer;
       }
     }


`;

const MenuItemWrappper = styled.div`
   padding: ${({padding}) => padding ? '0 15px 25px 15px' : '0 15px'};
   max-height: 300px;
   overflow: scroll;
`;

const StyledU = styled.ul`
  list-style-type: none;
  padding: 0px;
  position: absolute;
  width: 100%;
  background-color: #282C37;
  background-color: white;
  z-index: 1000;
  border-radius: 4px;
  border: 1px solid rgba(0, 122, 177, 0.30);
`;

export { Layout, StyledUl, Button, MenuItem, CheckMark, CheckMarkContainer, DropdownHeading, CheckMarkContainerNew, MenuItemNew, MenuItemWrappper, StyledU}